import React from 'react';
import styled from 'styled-components';
import {
  Container,
  H1,
  H4,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { Seo } from 'components';
import { NAME } from 'config';
import { secondary } from 'styles/colors';

const PrivacyPolicy: React.FC = React.memo(() => {
  const { isMobile } = useQuery();

  return (
    <>
      <Seo title={`Privacy Policy | ${NAME}`}>
        <meta name="robots" content="noindex" />
      </Seo>
      <Wrapper>
        <Container>
          <H1
            margin={isMobile ? '1.5rem 0' : '3rem 0'}
            textAlign={!isMobile && 'center'}
          >
            Privacy Policy
          </H1>
          <RegularStyled>
            In this privacy policy we, InnoDiets, UAB company code 305493571,
            with our registered office at Tarpupio str. 29, Mamavys, Trakai
            dstr. 21169, Lithuania, office address at Antakalnio str.17, Vilnius
            10312, Lithuania (<b>“Company”</b>, <b>“we”</b>, <b>“us”</b> or{' '}
            <b>“our”</b>), explain how we handle your personal data when you
            visit our website, use our apps, contact us through our official
            social media pages or email, and/or use our services.
          </RegularStyled>
          <RegularStyled>
            Please note that in case you purchase physical goods while using our
            services, UAB “Max Nutrition”, company code 305420438, with
            registered office at Gedimino av. 1A-13, Vilnius, office address at
            Antakalnio str.17, Vilnius 10312, Lithuania is responsible for data
            processing with regard to this particular service. In such cases{' '}
            <b>“Company”</b>, <b>“we”</b>, <b>“us”</b> or <b>“our”</b> shall
            refer to UAB “Max Nutrition”. In case you have any privacy related
            inquiries or requests associated to physical goods, you may contact
            us by email:{' '}
            <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
            .
          </RegularStyled>
          <RegularStyled>
            We will ask you to consent to our use of cookies in accordance with
            the terms of this notice when you first visit our website.
          </RegularStyled>
          <Regular>
            In this notice you will find the answers to the following questions:
          </Regular>
          <Ul>
            <Li>how we use your data;</Li>
            <Li>when we provide your data to others;</Li>
            <Li>how long we store your data;</Li>
            <Li>what is our marketing policy;</Li>
            <Li>what rights related to personal data you possess;</Li>
            <Li>how we use cookies;</Li>
            <Li>other issues that you should take into account.</Li>
          </Ul>
          <RegularStyled>
            In case of any inquiries or if you would like to exercise any of
            your rights provided in this notice, you may submit such inquiries
            and requests by means provided in Contacts section.
          </RegularStyled>
          <RegularStyled>
            You may also contact us regarding all privacy related issues by
            email:{' '}
            <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
            .
          </RegularStyled>
          <RegularStyled>
            All the definitions used in this privacy policy have the same
            meaning as prescribed in Company&rsquo;s General Conditions unless
            expressly provided otherwise in this privacy policy. This privacy
            policy forms an integral part of Company&rsquo;s General Conditions.
          </RegularStyled>
          <RegularStyled>
            In the event this privacy policy is translated into other languages
            and if there are differences between the English version and such
            translation, the English version shall prevail, unless otherwise
            provided.
          </RegularStyled>
          <H4Styled margin="0 0 1.5rem">
            How we use your personal data?
          </H4Styled>
          <Regular>This Section provides the following information:</Regular>
          <Ul>
            <Li>categories of personal data, that we process;</Li>
            <Li>
              in case of the personal data that we did not obtain directly from
              you, the source and specific categories of that data;
            </Li>
            <Li>
              in case of the personal data that we did not obtain directly from
              you, the source and specific categories of that data;
            </Li>
            <Li>
              the purposes for which we may process your personal data; and
            </Li>
            <Li>the legal bases of the processing.</Li>
          </Ul>
          <RegularStyled>
            We process <u>your account data</u> (&ldquo;
            <b>account data</b>&rdquo;). The account data may include your name
            and email address, phone number and other data that you provide
            while registering as well as your purchase history. We obtain such
            data directly from you. We process account data for the purposes of
            operating our website, providing our services, ensuring the security
            of our website and services and communicating with you. The legal
            basis for this processing is the performance of a contract between
            you and us and/or taking steps, at your request, to enter into such
            a contract as well as our legitimate interest, namely monitoring and
            improving our website and services.
          </RegularStyled>
          <RegularStyled>
            We process{' '}
            <u>information relating to provision of services by us to you</u>{' '}
            (&ldquo;<b>service data</b>&rdquo;). The service data may include
            your contact details (such as your email address), bank account and
            transaction details as well as other information that you provide to
            us while filling up the relevant questionnaires (such may include
            sensitive personal data, related to your health, in case such data
            is necessary to provide the relevant service). The service data is
            processed to supply goods and provide services as well as keep
            proper records of those transactions. The legal basis for this
            processing is the performance of a contract between you and us
            and/or taking steps, at your request, to enter into such a contract
            and our legitimate interests, namely the proper administration of
            our website and business. In case of sensitive personal data,
            related to your health the legal basis for processing is your
            explicit consent.
          </RegularStyled>
          <RegularStyled>
            We may process{' '}
            <u>
              information that you provide to us for the purpose of subscribing
              to our email messages and newsletters
            </u>{' '}
            (&ldquo;<b>messaging data</b>&rdquo;). The messaging data is
            processed to send you the relevant messages and newsletters. The
            legal basis for this processing is your consent. Also, if we have
            already sold goods or provided services for you via our website
            and/or apps, and you do not object, we may also process messaging
            data on the basis of our legitimate interest, namely seeking
            maintain and improve customer relations.
          </RegularStyled>
          <RegularStyled>
            We may process{' '}
            <u>information relating to any communication that you send to us</u>{' '}
            (&ldquo;<b>correspondence data</b>&rdquo;). The correspondence data
            may include the communication content and metadata associated with
            the communication. The correspondence data is processed for the
            purposes of communicating with you and record-keeping. The legal
            basis for this processing is our legitimate interests, namely the
            proper administration of our website and business, ensuring uniform
            and high quality consultation practice and for investigating
            disputes between you and our employees.
          </RegularStyled>
          <RegularStyled>
            We may process{' '}
            <u>
              information on your use of our website and/or apps as well as on
              your device
            </u>{' '}
            (&ldquo;<b>device data</b>&rdquo;) when you are browsing our website
            or use our apps. Device data may include IP address, geographical
            location, browser type and version, operating system, device type,
            screen resolution and (in case you agree to share such) your
            location data as well as information on the use of our website and
            apps (i.e. referral source, length of visit, page views and website
            navigation paths, as well as information about the timing, frequency
            and pattern of your service use). We obtain such data through the
            use of cookies and similar technologies. Device data is processed to
            enhance the apps and the website as well as to set default options.
            We also use such data to have a better understanding of how you use
            our website and services as well as for securing both the website
            and the apps. The legal basis for this processing is our legitimate
            interest, namely the proper management of our website, apps and
            business.
          </RegularStyled>
          <RegularStyled>
            We may process any of your personal data identified in this notice
            where necessary for the establishment, exercise or defence of legal
            claims, whether in court proceedings or in an administrative or
            out-of-court procedure. The legal basis for this processing is our
            legitimate interests, namely the protection and assertion of our
            legal rights, your legal rights and the legal rights of others.
          </RegularStyled>
          <RegularStyled>
            We may process any of your personal data identified in this notice
            where necessary for the purposes of obtaining or maintaining
            insurance coverage, managing risks, or obtaining professional
            advice. The legal basis for this processing is our legitimate
            interests, namely the proper protection of our business against
            risks.
          </RegularStyled>
          <RegularStyled>
            In addition to the specific purposes for which we may process your
            personal data set out in this Section, we may also process any of
            your personal data where such processing is necessary for compliance
            with a legal obligation to which we are subject, or in order to
            protect your vital interests or the vital interests of another
            natural person.
          </RegularStyled>
          <H4Styled>When we provide your data to others?</H4Styled>
          <RegularStyled>
            We may disclose your personal data{' '}
            <u>to any member of our group of companies</u> (including our
            subsidiaries, our ultimate holding company and all its subsidiaries)
            insofar as reasonably necessary for the purposes set out in this
            notice. Such may include internal administration purposes as well as
            provision/sharing of IT, payment or marketing services or data
            centres in the group.
          </RegularStyled>
          <RegularStyled>
            We may disclose your personal data{' '}
            <u>to our insurers and/or professional advisers</u> insofar as
            reasonably necessary for the purposes of obtaining or maintaining
            insurance coverage, managing risks, obtaining professional advice,
            or the establishment, exercise or defence of legal claims, whether
            in court proceedings or in an administrative or out-of-court
            procedure.
          </RegularStyled>
          <RegularStyled>
            We may disclose your personal data to our{' '}
            <u>anti-fraud, risks and compliance providers </u>
            insofar as reasonably necessary for the purposes of protecting your
            personal data and fulfilling our legal obligations.
          </RegularStyled>
          <RegularStyled>
            We may disclose your personal data{' '}
            <u>to our payment service providers</u>. We will share service data
            with our payment services providers only to the extent necessary for
            the purposes of processing your payments, transferring funds and
            dealing with complaints and queries relating to such payments and
            transfers.
          </RegularStyled>
          <RegularStyled>
            We may disclose your personal data{' '}
            <u>to other service providers </u>
            insofar as it is reasonably necessary to provide specific services
            (including, providers of servers and maintenance thereof, email
            service providers, service providers used for data analysis or
            marketing, call centres, customer satisfaction surveys or market
            research). We take all the necessary measures to ensure that such
            subcontractors would implement proper organisational and technical
            measures to ensure security and privacy of your personal data.
          </RegularStyled>
          <RegularStyled>
            In addition to the specific disclosures of personal data set out in
            this Section, we may disclose your personal data where such
            disclosure is necessary for compliance with a legal obligation to
            which we are subject, or in order to protect your vital interests or
            the vital interests of another natural person.
          </RegularStyled>
          <RegularStyled>
            Persons, indicated in this Section may be established outside the
            Republic of Lithuania, European Union and European Economic Area. In
            case we will transfer your personal data to such persons, we will
            take all the necessary and in the legal acts indicated measures to
            ensure that your privacy will remain properly secured, including
            where appropriate, signing standard contractual clauses for transfer
            of data. To find out more information regarding appropriate
            safeguards you may contact us via email:{' '}
            <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
            .
          </RegularStyled>
          <H4Styled>How long we store your data?</H4Styled>
          <Regular>
            Your personal data that we process for any purpose or purposes shall
            not be kept for longer than is necessary for that purpose or those
            purposes. In any case it shall be kept for no longer than:
          </Regular>
          <Ul>
            <Li>
              account data will be retained for no longer than 5 (five) years
              following your last update on the account;
            </Li>
            <Li>
              service data will be retained for no longer than 5 (five) years
              following the end of provision of services;
            </Li>
            <Li>
              messaging data will be retained for no longer than 2 (two) years
              following the provision of consent or, in case, the messaging data
              is being sent to the present clients in order to maintain and
              improve customer relations, for no longer than 2 (two) years
              following the end of provision of the respective services, unless
              you respectively withdraw your consent earlier or object to such
              processing;
            </Li>
            <Li>
              correspondence data will be retained for no longer than 6 (six)
              months following the end of such communication.
            </Li>
          </Ul>
          <RegularStyled>
            In some cases, it is not possible for us to specify in advance the
            periods for which your personal data will be retained. I. e.{' '}
            <u>device data</u> will be retained for as much as will be necessary
            for the relevant processing purposes.
          </RegularStyled>
          <RegularStyled>
            Notwithstanding the other provisions of this Section, we may retain
            your personal data where such retention is necessary for compliance
            with a legal obligation to which we are subject, or in order to
            protect your vital interests or the vital interests of another
            natural person.
          </RegularStyled>
          <H4Styled>Marketing communication</H4Styled>
          <RegularStyled>
            In case you consent, we will contact you via email or phone to
            inform on what we are up to. Also, if we already have provided
            services to you and you do not object, we will inform you about our
            other products that might interest you including other information
            related to such.
          </RegularStyled>
          <RegularStyled>
            You may opt-out of receiving marketing communications at any time.
            You may do so by choosing the relevant link in any of our marketing
            messages or contacting us via means provided in our website.
          </RegularStyled>
          <RegularStyled>
            Upon you having fulfilled any of the provided actions we will update
            your profile to ensure that you will not receive our marketing
            communication in the future.
          </RegularStyled>
          <RegularStyled>
            Please be informed that as our business activities consists of a
            network of closely related services, it may take a few days until
            all the systems are updated, thus you may continue to receive
            marketing communication while we are still processing your request.
          </RegularStyled>
          <RegularStyled>
            In any case, the opt-out of the marketing communications will not
            stop you from receiving communication directly related to the
            provision of services.
          </RegularStyled>
          <H4Styled>Your rights</H4Styled>
          <RegularStyled>
            In this Section, we have summarised the rights that you have under
            data protection laws. Some of the rights are complex thus we only
            provide the main aspects of such rights. Accordingly, you should
            read the relevant laws (first and foremost the General Data
            Protection Regulation (EU) 2016/679) and guidance from the
            regulatory authorities for a full explanation of these rights.
          </RegularStyled>
          <Regular>
            Your principal rights under data protection law are the following:
          </Regular>
          <Ul>
            <Li>the right to access data;</Li>
            <Li>the right to rectification;</Li>
            <Li>the right to erasure of your personal data;</Li>
            <Li>the right to restrict processing of your personal data;</Li>
            <Li>the right to object to processing of your personal data;</Li>
            <Li>the right to data portability;</Li>
            <Li>the right to complain to a supervisory authority; and</Li>
            <Li>the right to withdraw consent.</Li>
          </Ul>
          <RegularStyled>
            <u>The right to access data.</u> You have the right to confirmation
            as to whether or not we process your personal data and, where we do,
            access to the personal data, together with certain additional
            information. That additional information includes details of the
            purposes of the processing, the categories of personal data
            concerned and the recipients of the personal data. Providing the
            rights and freedoms of others are not affected, we will supply to
            you a copy of your personal data. The first copy will be provided
            free of charge, but additional copies may be subject to a reasonable
            fee.
          </RegularStyled>
          <RegularStyled>
            <u>The right to rectification</u>. You have the right to have any
            inaccurate personal data about you rectified and, taking into
            account the purposes of the processing, to have any incomplete
            personal data about you completed.
          </RegularStyled>
          <RegularStyled>
            In some circumstances you have{' '}
            <u>the right to the erasure of your personal data</u>. Those
            circumstances include when: (i) the personal data are no longer
            necessary in relation to the purposes for which they were collected
            or otherwise processed; (ii) you withdraw consent to consent-based
            processing and there are no other legal basis to process data; (iii)
            you object to the processing under certain rules of applicable data
            protection laws; (iv) the processing is for direct marketing
            purposes; or (v) the personal data have been unlawfully processed.
            However, there are exclusions of the right to erasure. Such
            exclusions include when processing is necessary: (i)&nbsp;for
            exercising the right of freedom of expression and information;
            (ii)&nbsp;for compliance with our legal obligation; or (iii) for the
            establishment, exercise or defence of legal claims.
          </RegularStyled>
          <RegularStyled>
            In some circumstances you have{' '}
            <u>the right to restrict the processing of your personal data</u>.
            Those circumstances are when: (i) you contest the accuracy of the
            personal data; (ii)&nbsp;processing is unlawful but you oppose
            erasure; (iii) we no longer need the personal data for the purposes
            of our processing, but you require personal data for the
            establishment, exercise or defence of legal claims; and (iv) you
            have objected to processing, pending the verification of that
            objection. Where processing has been restricted on this basis, we
            may continue to store your personal data, however we will only
            further process such data in any other way: (i) with your consent;
            (ii) for the establishment, exercise or defence of legal claims;
            (iii) for the protection of the rights of another person; or (iv)
            for reasons of important public interest.
          </RegularStyled>
          <RegularStyled>
            You have{' '}
            <u>the right to object to our processing of your personal data</u>{' '}
            on grounds relating to your particular situation, but only to the
            extent that the legal basis for the processing is that the
            processing is necessary for: the performance of a task carried out
            in the public interest or the purposes of the legitimate interests
            pursued by us or by a third party. If you make such an objection, we
            will cease to process the personal information unless we can
            demonstrate compelling legitimate grounds for the processing which
            override your interests, rights and freedoms, or the processing is
            for the establishment, exercise or defence of legal claims.
          </RegularStyled>
          <RegularStyled>
            You have{' '}
            <u>
              the right to object to our processing of your personal data for
              direct marketing purposes
            </u>{' '}
            (including profiling for direct marketing purposes). If you make
            such an objection, we will cease to process your personal data for
            this purpose.
          </RegularStyled>
          <RegularStyled>
            You have{' '}
            <u>
              the right to object to our processing of your personal data for
              scientific or historical research purposes or statistical purposes
            </u>{' '}
            on grounds relating to your particular situation, unless the
            processing is necessary for the performance of a task carried out
            for reasons of public interest.
          </RegularStyled>
          <Regular>
            <u>The right to data portability</u>. To the extent that the legal
            basis for our processing of your personal data is:
          </Regular>
          <Ul>
            <Li>consent; or</Li>
            <Li>
              performance of a contract or steps to be taken at your request
              prior to entering into a contract, necessary to enter into such,
            </Li>
          </Ul>
          <RegularStyled>
            you have the right to receive your personal data from us in a
            structured, commonly used and machine-readable format. However, this
            right does not apply where it would adversely affect the rights and
            freedoms of others.
          </RegularStyled>
          <RegularStyled>
            If you consider that our processing of your personal information
            infringes data protection laws, you have a legal{' '}
            <u>right to lodge a complaint with a supervisory authority</u>{' '}
            responsible for data protection. You may do so in the EU member
            state of your habitual residence, your place of work or the place of
            the alleged infringement. Our data processing is supervised by State
            Data Protection Inspectorate of the Republic of Lithuania,
            registered office at L. Sapiegos St. 17, LT-10312 Vilnius,{' '}
            <Link href="http://www.ada.lt">www.ada.lt</Link>.
          </RegularStyled>
          <RegularStyled>
            To the extent that the legal basis for our processing of your
            personal information is consent, you have{' '}
            <u>the right to withdraw that consent at any time</u>. Withdrawal
            will not affect the lawfulness of processing before the withdrawal.
          </RegularStyled>
          <RegularStyled>
            In addition to specific measure provided in this Section or the
            website you may also exercise any of the rights indicated herein by
            contacting us by email:{' '}
            <Link href="mailto:hello@zerosmokes.com">hello@zerosmokes.com</Link>
            .
          </RegularStyled>
          <H4Styled>About cookies</H4Styled>
          <RegularStyled>
            Cookies are small textual files containing identifier that is sent
            by a web server to your web browser and is stored by the browser.
            The identifier is then sent back to the server each time the browser
            requests a page from the server.
          </RegularStyled>
          <RegularStyled>
            Cookies do not typically contain any information that personally
            identifies a user, but personal information that we store about you
            may be linked to the information stored in and obtained from
            cookies.
          </RegularStyled>

          <H4Styled>Cookies that we use</H4Styled>

          <Regular>
            In the website we use cookies of three main types, for the following
            purposes:
          </Regular>
          <Ul>
            <Li>
              Required cookies &ndash; used to ensure proper performance of the
              website, security of customers and their data, provision of
              high-quality services;
            </Li>
            <Li>
              Functional cookies &ndash; used to enhance the website user
              experience, analyse the use of the system and in accordance to
              such improve the provision of services;
            </Li>
            <Li>
              Advertising cookies &ndash; used to observer user online behaviour
              and optimize marketing campaigns according to such information.
            </Li>
          </Ul>

          <H4Styled>Cookies used by our service providers</H4Styled>

          <RegularStyled>
            Our service providers use cookies and those cookies may be stored on
            your computer when you visit our website.
          </RegularStyled>
          <Regular>We use:</Regular>
          <Ul>
            <Li>
              <u>Google Analytics cookies</u> to observe our website traffic.
              Cookies used for this purpose help us detect website errors as
              well as measure website bandwidth. You can view the privacy policy
              of Google Analytics{' '}
              <Link href="https://policies.google.com/privacy?hl=en">here</Link>
              ;
            </Li>
            <Li>
              <u>Youtube cookies</u> to display in our website content uploaded
              in Youtube. Cookies used for this purpose help us maintain
              integrity, create informative and dynamic website. You can view
              the privacy policy of Youtube{' '}
              <Link href="https://www.youtube.com/static?template=privacy_guidelines">
                here
              </Link>
              ;
            </Li>
            <Li>
              <u>Twitter cookies</u> to display in our website content posted in
              Twitter. Cookies used for this purpose help us maintain integrity,
              create informative and dynamic website. You can view the privacy
              policy of Twitter{' '}
              <Link href="https://twitter.com/en/privacy">here</Link>;
            </Li>
            <Li>
              <u>Google Maps cookies</u> to, if the user permits, determine
              users location. Cookies used for this purpose help us adapt
              website settings in accordance to user&rsquo;s location and
              improve user experience in our website. You can view the privacy
              policy of Google Maps{' '}
              <Link href="https://policies.google.com/privacy?hl=en">here</Link>
              ;
            </Li>
            <Li>
              <u>Doubleclick cookies</u> to control the display of ads to our
              users. Cookies used for this purpose help us distinguish users
              that already use our services and reduce or stop displaying our
              ads to such users. You can view the privacy policy of Doubleclick{' '}
              <Link href="https://policies.google.com/?hl=en">here</Link>;
            </Li>
            <Li>
              <u>Facebook cookies</u>
              to manage the display of ads to our users. Cookies used for this
              purpose help us distinguish users that already use our services
              and reduce or stop displaying our ads to such users. You can view
              the privacy policy of Facebook{' '}
              <Link href="https://www.facebook.com/legal/FB_Work_Privacy">
                here
              </Link>
              ;
            </Li>
            <Li>
              <u>Google Tag Manager cookies</u>
              to control advertising cookies. Cookies used for this purpose help
              us properly distribute ads to users. You can view the privacy
              policy of <u>Google Tag Manager</u>{' '}
              <Link href="https://policies.google.com/privacy?hl=en">here</Link>
              ;
            </Li>
            <Li>
              <u>Hotjar cookies</u>
              &nbsp;to observe how users use our website. Cookies used for this
              purpose help us observe the performance of the website and analyse
              how we can improve our website. You can view the privacy policy of
              Hotjar&nbsp;
              <Link href="https://www.hotjar.com/legal/policies/privacy">
                here
              </Link>
              .
            </Li>
          </Ul>

          <H4Styled>How can you manage cookies?</H4Styled>
          <RegularStyled>
            Most browsers allow you to refuse to accept cookies and to delete
            cookies. The methods for doing so vary from browser to browser, and
            from version to version. You can however obtain up-to-date
            information about blocking and deleting cookies via information
            provided in the relevant browser website, for example{' '}
            <Link href="https://support.google.com/chrome/answer/95647?hl=en">
              Chrome
            </Link>
            ;{' '}
            <Link href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">
              Firefox
            </Link>
            ;{' '}
            <Link href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies">
              Internet Explorer
            </Link>
            ;{' '}
            <Link href="https://support.apple.com/en-gb/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
              Safari
            </Link>
            .
          </RegularStyled>
          <RegularStyled>
            Blocking all cookies will have a negative impact upon the usability
            of many websites.
          </RegularStyled>
          <RegularStyled>
            If you block cookies, you will not be able to use all the features
            on our website.
          </RegularStyled>
          <H4Styled>Third party websites</H4Styled>
          <RegularStyled>
            In the website you may find links to and from partner sites,
            information sources and related party websites. Please take note
            that such third party website that you will visit by clicking on
            links have their own privacy policies and we take no responsibility
            regarding such privacy policies. We recommend familiarising with
            privacy policies of such websites before providing any personal data
            to such.
          </RegularStyled>

          <RegularStyled>Children personal data</RegularStyled>
          <RegularStyled>
            Our website and services are targeted at persons over the age of 18.
          </RegularStyled>
          <RegularStyled>
            If we have reason to believe that we hold personal data of a person
            under that age in our databases without having consent from the
            parent rights holder, we will delete that personal data.
          </RegularStyled>
          <H4Styled>Updating your data</H4Styled>
          <RegularStyled>
            Please let us know if the personal information that we hold about
            you needs to be corrected or updated.
          </RegularStyled>
          <H4Styled>Changes to the notice</H4Styled>
          <RegularStyled>
            Any changes to this notice will be published in the website and, in
            case of material changes, we may inform you about such via email or
            by other means which we will deem the most fitting in a particular
            case.
          </RegularStyled>
        </Container>
      </Wrapper>
    </>
  );
});

PrivacyPolicy.displayName = 'Privacy policy';

export default PrivacyPolicy;

const Wrapper = styled.div`
  padding: 0 1rem;
`;

const RegularStyled = styled(Regular)`
  margin: 0 0 1rem;
`;

const H4Styled = styled(H4)`
  margin: 0 0 1.5rem;
`;

const Ul = styled.ul`
  margin: 0 0 1rem 1.5rem;
`;

const Li = styled(Regular).attrs({ as: 'li' })``;

const Link = styled.a`
  color: ${secondary};
`;
